var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import BarChartIcon from "@material-ui/icons/BarChart";
import DashboardIcon from "@material-ui/icons/Dashboard";
import LayersIcon from "@material-ui/icons/Layers";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { UserSettingsWrapped } from "../../../../pkgs/src/components/UserSettingsWrapped";
var SidebarItems = function () {
    var _a = useState(false), settingsVisible = _a[0], setSettingsVisible = _a[1];
    var showSettings = function () {
        setSettingsVisible(true);
    };
    var hideSettings = function () {
        setSettingsVisible(false);
    };
    return (_jsxs(_Fragment, { children: [_jsx(UserSettingsWrapped, { isOpen: settingsVisible, close: hideSettings }, void 0),
            _jsxs(List, { children: [_jsxs(ListItem, __assign({ button: true, onClick: showSettings }, { children: [_jsx(ListItemIcon, { children: _jsx(DashboardIcon, {}, void 0) }, void 0),
                            _jsx(ListItemText, { primary: "Settings" }, void 0)] }), void 0),
                    _jsxs(ListItem, __assign({ button: true }, { component: NavLink, to: "/Other" }, { children: [_jsx(ListItemIcon, { children: _jsx(BarChartIcon, {}, void 0) }, void 0),
                            _jsx(ListItemText, { primary: "Reports" }, void 0)] }), void 0),
                    _jsxs(ListItem, __assign({ button: true }, { component: NavLink, to: "/Other" }, { children: [_jsx(ListItemIcon, { children: _jsx(LayersIcon, {}, void 0) }, void 0),
                            _jsx(ListItemText, { primary: "Integrations" }, void 0)] }), void 0)] }, void 0)] }, void 0));
};
export { SidebarItems };
